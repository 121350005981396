import { CardCategory3Props } from "./CardCategory3";

export const CATS_DISCOVER: CardCategory3Props[] = [
  {
    name: "Upload your design, We Print",
    desc: "Upload your image,<br /> get the perfect <br /> print delivered.",
    featuredImage: "https://genstorageaccount3116.blob.core.windows.net/printme-images/sales-1.webp",
    color: "bg-blue-50",
    href: "/your-design",
    buttonText: "Upload now",
  },
  {
    name: "Diverse Art Styles",
    desc: "Vintage, <br />modern, nature,<br />abstract prints.",
    featuredImage: "https://genstorageaccount3116.blob.core.windows.net/printme-images/illustaration-hang-picture-in-frame.webp",
    color: "bg-yellow-50",
  },
  {
    name: "Quality You Can Feel",
    desc: "Experience our<br /> premium Giclée<br /> prints",
    featuredImage: "https://genstorageaccount3116.blob.core.windows.net/printme-images/epson-printer.webp",
    color: "bg-red-50",
  },
  {
    name: "Visit Our Space",
    desc: "Pick up prints,<br />enjoy coffee.",
    featuredImage: "https://genstorageaccount3116.blob.core.windows.net/printme-images/coffee-invitation.webp",
    color: "bg-green-50",
    href: "/about",
    buttonText: "Visit Us",
  },
];
