import React, { FC } from "react";
import NcImage from "../../shared/NcImage/NcImage";
import explore1Svg from "../../data/images/collections/explore1.svg";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Image from "../../shared/Image";
import Link from "../../shared/Link";
import { useTranslation } from "react-i18next";

export interface CardCategory4Props {
  className?: string;
  featuredImage?: string;
  bgSVG?: string;
  name: string;
  desc: string;
  color?: string;
  count?: number;
  link?: string;
}

const CardCategory4: FC<CardCategory4Props> = ({
  className = "",
  featuredImage = ".",
  bgSVG = explore1Svg,
  name,
  desc,
  color = "bg-rose-50",
  count,
  link
}) => {
  const {t} = useTranslation();
  return (
    <div
      className={`nc-CardCategory4 relative w-full aspect-w-12 aspect-h-11 h-0 rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 group hover:nc-shadow-lg transition-shadow ${className}`}
    >
      <div>
        <div className="absolute bottom-0 right-0 max-w-[280px] opacity-80 hidden md:block ">
          <Image width="200" height="200" src={bgSVG} alt="" />
        </div>

        <div className="absolute inset-5 sm:inset-8 flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <NcImage
              alt=""
              src={featuredImage}
              containerClassName={`w-14 h-14 md:w-32 md:h-32 rounded-full overflow-hidden z-0 ${color}`}
              width={80}
              height={80}
            />
            <span className="text-xs text-slate-700 dark:text-neutral-300 font-medium pl-1 md:ml-0">
              {count} {t('products')} 
            </span>
          </div>

          <div className="">
            <span
              className={`block mb-2 text-xs md:text-sm text-slate-500 dark:text-slate-400 hidden md:block`}
            >
              {t(desc)}
            </span>
            <h2 className={`text-md md:text-3xl font-semibold`}>{t(name)}</h2>
          </div>

          <Link
            href={"/search"}
            className="flex items-center text-xs md:text-sm group-hover:text-primary-500 transition-colors"
          >
            <span>{t('See Prints')}</span>
            <ArrowRightIcon className="w-4 h-4 ml-2.5" />
          </Link>
        </div>
      </div>

      <Link href={link ?? "/search"} aria-label="Show All Categories"></Link>
    </div>
  );
};

export default CardCategory4;
